import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const AestheticDoctorsCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "10.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(relativePath: { eq: "testimonials/Dr-Alice-Scott.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Aesthetic Doctors | Business & Executive Coaching in London";
	const description =
		"Managing and owning an aesthetic practice is about more than just your skill with a syringe or laser - it's also about business knowledge and skill.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Aesthetic Doctors",
				item: {
					url: `${siteUrl}/industries/aesthetic-doctors`,
					id: `${siteUrl}/industries/aesthetic-doctors`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/aesthetic-doctors`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Aesthetic doctors business & executive coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Aesthetic Doctors"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								Managing and owning an aesthetic practice is about more than
								just your skill with a syringe or laser - it's also about
								business knowledge and skill. You're in need of a partner who
								understands the aesthetic and medical industry, someone who can
								help you to hit those business targets, and steer you through
								the tricky decisions, as well as recognising the particular
								hurdles you face in the aesthetic field.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Is business and executive coaching the solution?
							</h3>
							<p>
								With my extensive experience in business, spanning over 35
								years, I've coached a range of different healthcare and medical
								specialists, including aesthetic doctors, who navigate the
								unique challenges of managing their clinics. As an aesthetic
								practice owner, you balance the art of patient care with clinic
								management, making critical decisions every day, all while
								trying to build a career that's as rewarding personally as it is
								professionally.
							</p>
							<p>
								If you're an aesthetic doctor looking for transformation, eager
								to streamline your clinic's operations, or in search of ways to
								make your practice more fulfilling, our bespoke business and
								executive coaching services are designed to support your
								ambitions.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								What will your future look like...
							</h3>

							<p>
								Imagine if you could look ahead into your own future with
								absolute certainty and see the transformative impact on your
								aesthetic practice by...
							</p>
							<ul className="pl-4">
								<li>
									Increasing your confidence in every decision you make and each
									interaction with your patients?
								</li>
								<li>
									Elevating your leadership and communication skills,
									encouraging better teamwork and improving the patient
									experience?
								</li>
								<li>
									Becoming more assertive and decisive, navigating the
									complexities of the aesthetic medicine world?
								</li>
								<li>
									Feeling a fresh wave of motivation and enthusiasm, pushing the
									boundaries in patient care quality?
								</li>
								<li>
									Taking a courageous leap to invest in a groundbreaking
									aesthetic solution or enhancing your practice's offerings?
								</li>
							</ul>
							<p>
								Imagine the effect these changes could have on your aesthetic
								practice, your patients, and your sense of personal achievement.
							</p>
							<p>
								So, what's holding you back from turning these 'what ifs' into
								your very own reality? Let's dive in together and begin a
								journey towards business success.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What has been holding you back?</h2>
							<ul className="pl-4">
								<li>
									Do you ever feel like you're navigating your aesthetic
									practice alone, overwhelmed by endless decisions and the daily
									pressures that come with it?
								</li>
								<li>
									Are important choices about the future direction of your
									practice on your mind, leaving you wondering which road to
									take?
								</li>
								<li>
									Wanting to discover your key strengths and weaknesses, and
									figure out how to use them to your advantage?
								</li>
								<li>
									Have you noticed a decrease in your usual productivity,
									affecting your aesthetic practice?
								</li>
								<li>
									Are you searching for that first spark of enthusiasm and a
									sense of purpose in your aesthetics business?
								</li>
								<li>
									Do you have innovative ideas to enhance patient care or your
									aesthetic practice but need a business and executive coach to
									delve into these ideas with?
								</li>
							</ul>
							<p>
								Facing challenges within the aesthetics industry is not
								uncommon. These challenges can affect not only your work life
								balance as a business owner but also the success and expansion
								of your aesthetics practice.
							</p>
							<p>
								This is where I step in with my aesthetic business and executive
								coaching services.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Business and executive coaching, to help you grow
							</h2>
							<p>
								Together, we'll work on sharpening your leadership abilities,
								streamlining your aesthetic clinic operations, and pushing your
								professional growth in a market that's both competitive and
								rapidly changing.
							</p>
							<p>
								Understanding the demands on your time, I offer flexible,
								virtual coaching sessions designed to slot seamlessly into your
								hectic schedule. We'll tackle everything important for the
								success of your aesthetics practice, including:
							</p>
							<ul className="pl-4">
								<li>Personal and Professional Growth for Aesthetic Doctors</li>
								<li>
									Leadership Coaching Customised for Owners of Aesthetic Clinics
								</li>
								<li>Strategic Business Planning and Management</li>
							</ul>
							<p>
								What sets me apart from other business coaches is my refusal to
								apply a generic one-size-fits all approach. Instead, each
								session is tailored to address the specific needs of you and
								your aesthetics clinic, providing bespoke advice and strategies
								to navigate not just the challenges but also to take on the
								opportunities that you face.
							</p>
							<p>
								Let's start this business and executive coaching journey
								together, elevating your aesthetics practice to the level of
								excellence you aim for in the constantly evolving landscape of
								healthcare and aesthetics.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Why should you trust a business coach?</h2>
							<p>
								Throughout my journey of self-employment, I've navigated the
								entire range of experiences that come with building a career -
								from the thrilling successes to the unavoidable challenges. With
								a solid foundation in business education (with a DMS & MBA),
								accompanied by five years of counselling and psychotherapy
								training, and nearly forty years of succeeding in business.
							</p>
							<p>
								Together, we'll create a path towards achieving not only the
								professional satisfaction you're aiming for but also the
								work-life balance you deserve. Let's ensure your aesthetic
								practice succeeds within the aesthetics industry.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Dr Alice Scott. General Practitioner and Menopause Specialist. Essex Private Doctors."
						review="Having launched my business, the enormity of the ongoing management, growth and business development felt overwhelming. Starting coaching with Jason is the best business decision I have made so far. His calm, methodical and analytical approach has been invaluable. Sometimes it is easy to get caught up in a situation yourself, making you prone to emotional decision making, and I have found that Jason has been a good sounding board. He is encouraging to help you to push on with what your ultimate goals are. I am still having regular business coaching with Jason."
						linkedIn="https://www.linkedin.com/in/dr-alice-scott-45b268118/"
						profilePic={daniel}
					/>

					<div className="iframe-container-new mb-4">
						<iframe
							loading="lazy"
							className="responsive-iframe"
							src="https://www.youtube.com/embed/7F0Rj6Vtos8?si=uXiMM4k-5jonalvY"
							title="Testimonial | Jason Cornes"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Are you ready to take on business success?
							</h2>
							<p>
								Predicting the future of your surgical practice isn't something
								we can do. I can't lay out exactly how things might change if
								you take on new surgical techniques, start each day filled with
								confidence and a sense of purpose, or elevate your team's
								performance to new levels. But one thing is for sure:
							</p>
							<p>If we stick to the old ways, nothing's going to improve.</p>
							<p>
								It's truly amazing what we can achieve together when we use
								coaching to focus and reflect on what you really want to
								accomplish in your surgical business.
							</p>
							<p>
								You've got everything to gain and absolutely nothing to lose, so
								how about we schedule a chat? Let's dive into the potential
								improvements and advancements we can bring to your business and
								professional life as a surgeon.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">
								Book a call today with your new business coach
							</h3>
							<p>
								Why don't we have a chat? It gives us an opportunity to get to
								know each other and see how business and executive coaching can
								help to make a difference within your aesthetics practice.
							</p>
							<p>
								And just between us, no matter how skilled you are in your
								running your aesthetic practice, it's clear you can't juggle
								everything on your own. What you need is a business coaching
								partner. Someone who's got your back with honest feedback, helps
								you set those big goals and actually reach them, and is there
								for you when those tough decisions come knocking. Let's make
								sure you've got the support you need to thrive in your business.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default AestheticDoctorsCoachingPage;
